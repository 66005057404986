import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/ge/mit/geyang.github.io/src/components/mdx-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`First of all, let's take a look at the implementation:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "python"
    }}><pre parentName="div" {...{
        "className": "python"
      }}><code parentName="pre" {...{
          "className": "python"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`class`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`FF`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`nn`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`Module`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`def`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`__init__`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`self`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` band_limit`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token builtin"
          }}>{`int`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` p`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token builtin"
          }}>{`float`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
        self`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`b_s `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` torch`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`arange`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` band_limit `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
        self`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`a_s `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{` torch`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token builtin"
          }}>{`pow`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`self`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`b_s`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` p`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
        `}<span parentName="code" {...{
            "className": "token builtin"
          }}>{`super`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`__init__`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`def`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`forward`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`self`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` x`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
        `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` torch`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`cat`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`
            self`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`a_s `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`*`}</span>{` torch`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`sin`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`2.`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`*`}</span>{` np`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`pi `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`*`}</span>{` x `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`*`}</span>{` self`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`b_s`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
            self`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`a_s `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`*`}</span>{` torch`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`cos`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`2.`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`*`}</span>{` np`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`pi `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`*`}</span>{` x `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`*`}</span>{` self`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`b_s`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` dim`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{` torch`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`norm`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`self`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`a_s`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre></div>
    <p>{`Now there are a few crucial points. First of all, there are
these two parameters `}<code parentName="p" {...{
        "className": "text"
      }}>{`a`}</code>{` and `}<code parentName="p" {...{
        "className": "text"
      }}>{`b`}</code>{`. In fourier features, the
parameter `}<code parentName="p" {...{
        "className": "text"
      }}>{`b`}</code>{` corresponds to the frequencies. Fourier features
use equally spaced octaves.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "python"
    }}><pre parentName="div" {...{
        "className": "python"
      }}><code parentName="pre" {...{
          "className": "python"
        }}>{`net `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` FF`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`8`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
doc`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`print`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"frequencies (in 2π):"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` net`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`b_s`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre></div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "text"
      }}><code parentName="pre" {...{
          "className": "text"
        }}>{`frequencies (in 2π): tensor([1, 2, 3, 4, 5, 6, 7, 8])`}</code></pre></div>
    <p>{`An important property in the examples used in `}<sup parentName="p" {...{
        "id": "fnref-tanick"
      }}><a parentName="sup" {...{
          "href": "#fn-tanick",
          "className": "footnote-ref"
        }}>{`tanick`}</a></sup>{` is that
if your input is between `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`[`}</mo><mn parentName="mrow">{`0`}</mn><mo parentName="mrow" {...{
                    "separator": "true"
                  }}>{`,`}</mo><mn parentName="mrow">{`1`}</mn><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`)`}</mo></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`[0, 1)`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "1em",
                  "verticalAlign": "-0.25em"
                }
              }}></span><span parentName="span" {...{
                "className": "mopen"
              }}>{`[`}</span><span parentName="span" {...{
                "className": "mord"
              }}>{`0`}</span><span parentName="span" {...{
                "className": "mpunct"
              }}>{`,`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.16666666666666666em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}>{`1`}</span><span parentName="span" {...{
                "className": "mclose"
              }}>{`)`}</span></span></span></span></span>{`, you need to make sure that
the frequencies `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow" {...{
                    "mathvariant": "double-struck"
                  }}>{`b`}</mi></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`\\mathbb b`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.69444em",
                  "verticalAlign": "0em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`b`}</span></span></span></span></span>{` captures the lowest mode (the first
octave) of your entire range. Otherwise you will get aliasing.`}</p>
    <h3>{`Important Considerations`}</h3>
    <p>{`So for the range `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`[`}</mo><mn parentName="mrow">{`0`}</mn><mo parentName="mrow" {...{
                    "separator": "true"
                  }}>{`,`}</mo><mn parentName="mrow">{`1`}</mn><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`)`}</mo></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`[0, 1)`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "1em",
                  "verticalAlign": "-0.25em"
                }
              }}></span><span parentName="span" {...{
                "className": "mopen"
              }}>{`[`}</span><span parentName="span" {...{
                "className": "mord"
              }}>{`0`}</span><span parentName="span" {...{
                "className": "mpunct"
              }}>{`,`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.16666666666666666em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}>{`1`}</span><span parentName="span" {...{
                "className": "mclose"
              }}>{`)`}</span></span></span></span></span>{`, this means the lowest mode spans the
full `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mn parentName="mrow">{`2`}</mn><mi parentName="mrow">{`π`}</mi></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`2 \\pi`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.64444em",
                  "verticalAlign": "0em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}>{`2`}</span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.03588em"
                }
              }}>{`π`}</span></span></span></span></span>{` over that range. `}</p>
    <p>{`Now suppose we only take 1 fourier component (both `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`sin`}</mi><mo parentName="mrow">{`⁡`}</mo></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`\\sin`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.66786em",
                  "verticalAlign": "0em"
                }
              }}></span><span parentName="span" {...{
                "className": "mop"
              }}>{`sin`}</span></span></span></span></span>{` and
`}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`cos`}</mi><mo parentName="mrow">{`⁡`}</mo></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`\\cos`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.43056em",
                  "verticalAlign": "0em"
                }
              }}></span><span parentName="span" {...{
                "className": "mop"
              }}>{`cos`}</span></span></span></span></span>{`), this range `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`[`}</mo><mn parentName="mrow">{`0`}</mn><mo parentName="mrow" {...{
                    "separator": "true"
                  }}>{`,`}</mo><mn parentName="mrow">{`1`}</mn><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`)`}</mo></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`[0, 1)`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "1em",
                  "verticalAlign": "-0.25em"
                }
              }}></span><span parentName="span" {...{
                "className": "mopen"
              }}>{`[`}</span><span parentName="span" {...{
                "className": "mord"
              }}>{`0`}</span><span parentName="span" {...{
                "className": "mpunct"
              }}>{`,`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.16666666666666666em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}>{`1`}</span><span parentName="span" {...{
                "className": "mclose"
              }}>{`)`}</span></span></span></span></span>{` is mapped to a circle in the
phase space. If the a data point falls `}<strong parentName="p">{`outside`}</strong>{` of this range,
it would circle back in the circle and become aliased. Therefore
we need to make sure the lowest frequency components has longer
wavelength than the range.`}</p>
    <h2>{`Controlling the Spectral Bias`}</h2>
    <p>{`Now a second parameter is `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`a`}</mi></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`a`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.43056em",
                  "verticalAlign": "0em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`a`}</span></span></span></span></span>{`. This is the set of weights
for each of the fourier components in `}</p>
    <div {...{
      "className": "math math-display"
    }}><span parentName="div" {...{
        "className": "katex-display"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML",
              "display": "block"
            }}><semantics parentName="math"><mrow parentName="semantics"><mtext parentName="mrow">{`out`}</mtext><mo parentName="mrow">{`=`}</mo><mo parentName="mrow">{`∑`}</mo><mrow parentName="mrow"><msub parentName="mrow"><mi parentName="msub">{`a`}</mi><mi parentName="msub">{`i`}</mi></msub><mi parentName="mrow">{`sin`}</mi><mo parentName="mrow">{`⁡`}</mo><mo parentName="mrow" {...{
                      "stretchy": "false"
                    }}>{`(`}</mo><mn parentName="mrow">{`2`}</mn><mi parentName="mrow">{`π`}</mi><msub parentName="mrow"><mi parentName="msub">{`b`}</mi><mi parentName="msub">{`i`}</mi></msub><mi parentName="mrow">{`x`}</mi><mo parentName="mrow" {...{
                      "stretchy": "false"
                    }}>{`)`}</mo><mo parentName="mrow">{`+`}</mo><msub parentName="mrow"><mi parentName="msub">{`a`}</mi><mi parentName="msub">{`i`}</mi></msub><mi parentName="mrow">{`cos`}</mi><mo parentName="mrow">{`⁡`}</mo><mo parentName="mrow" {...{
                      "stretchy": "false"
                    }}>{`(`}</mo><mn parentName="mrow">{`2`}</mn><mi parentName="mrow">{`π`}</mi><msub parentName="mrow"><mi parentName="msub">{`b`}</mi><mi parentName="msub">{`i`}</mi></msub><mi parentName="mrow">{`x`}</mi><mo parentName="mrow" {...{
                      "stretchy": "false"
                    }}>{`)`}</mo></mrow></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`\\text{out} = \\sum {a_i \\sin(2\\pi b_i x) + a_i \\cos(2\\pi b_i x)}`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.61508em",
                  "verticalAlign": "0em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord text"
              }}><span parentName="span" {...{
                  "className": "mord"
                }}>{`out`}</span></span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2777777777777778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2777777777777778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "1.6000100000000002em",
                  "verticalAlign": "-0.55001em"
                }
              }}></span><span parentName="span" {...{
                "className": "mop op-symbol large-op",
                "style": {
                  "position": "relative",
                  "top": "-0.000004999999999977245em"
                }
              }}>{`∑`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.16666666666666666em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord"
                }}><span parentName="span" {...{
                    "className": "mord mathnormal"
                  }}>{`a`}</span><span parentName="span" {...{
                    "className": "msupsub"
                  }}><span parentName="span" {...{
                      "className": "vlist-t vlist-t2"
                    }}><span parentName="span" {...{
                        "className": "vlist-r"
                      }}><span parentName="span" {...{
                          "className": "vlist",
                          "style": {
                            "height": "0.31166399999999994em"
                          }
                        }}><span parentName="span" {...{
                            "style": {
                              "top": "-2.5500000000000003em",
                              "marginLeft": "0em",
                              "marginRight": "0.05em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "2.7em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "sizing reset-size6 size3 mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mathnormal mtight"
                              }}>{`i`}</span></span></span></span><span parentName="span" {...{
                          "className": "vlist-s"
                        }}>{`​`}</span></span><span parentName="span" {...{
                        "className": "vlist-r"
                      }}><span parentName="span" {...{
                          "className": "vlist",
                          "style": {
                            "height": "0.15em"
                          }
                        }}><span parentName="span"></span></span></span></span></span></span><span parentName="span" {...{
                  "className": "mspace",
                  "style": {
                    "marginRight": "0.16666666666666666em"
                  }
                }}></span><span parentName="span" {...{
                  "className": "mop"
                }}>{`sin`}</span><span parentName="span" {...{
                  "className": "mopen"
                }}>{`(`}</span><span parentName="span" {...{
                  "className": "mord"
                }}>{`2`}</span><span parentName="span" {...{
                  "className": "mord mathnormal",
                  "style": {
                    "marginRight": "0.03588em"
                  }
                }}>{`π`}</span><span parentName="span" {...{
                  "className": "mord"
                }}><span parentName="span" {...{
                    "className": "mord mathnormal"
                  }}>{`b`}</span><span parentName="span" {...{
                    "className": "msupsub"
                  }}><span parentName="span" {...{
                      "className": "vlist-t vlist-t2"
                    }}><span parentName="span" {...{
                        "className": "vlist-r"
                      }}><span parentName="span" {...{
                          "className": "vlist",
                          "style": {
                            "height": "0.31166399999999994em"
                          }
                        }}><span parentName="span" {...{
                            "style": {
                              "top": "-2.5500000000000003em",
                              "marginLeft": "0em",
                              "marginRight": "0.05em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "2.7em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "sizing reset-size6 size3 mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mathnormal mtight"
                              }}>{`i`}</span></span></span></span><span parentName="span" {...{
                          "className": "vlist-s"
                        }}>{`​`}</span></span><span parentName="span" {...{
                        "className": "vlist-r"
                      }}><span parentName="span" {...{
                          "className": "vlist",
                          "style": {
                            "height": "0.15em"
                          }
                        }}><span parentName="span"></span></span></span></span></span></span><span parentName="span" {...{
                  "className": "mord mathnormal"
                }}>{`x`}</span><span parentName="span" {...{
                  "className": "mclose"
                }}>{`)`}</span><span parentName="span" {...{
                  "className": "mspace",
                  "style": {
                    "marginRight": "0.2222222222222222em"
                  }
                }}></span><span parentName="span" {...{
                  "className": "mbin"
                }}>{`+`}</span><span parentName="span" {...{
                  "className": "mspace",
                  "style": {
                    "marginRight": "0.2222222222222222em"
                  }
                }}></span><span parentName="span" {...{
                  "className": "mord"
                }}><span parentName="span" {...{
                    "className": "mord mathnormal"
                  }}>{`a`}</span><span parentName="span" {...{
                    "className": "msupsub"
                  }}><span parentName="span" {...{
                      "className": "vlist-t vlist-t2"
                    }}><span parentName="span" {...{
                        "className": "vlist-r"
                      }}><span parentName="span" {...{
                          "className": "vlist",
                          "style": {
                            "height": "0.31166399999999994em"
                          }
                        }}><span parentName="span" {...{
                            "style": {
                              "top": "-2.5500000000000003em",
                              "marginLeft": "0em",
                              "marginRight": "0.05em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "2.7em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "sizing reset-size6 size3 mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mathnormal mtight"
                              }}>{`i`}</span></span></span></span><span parentName="span" {...{
                          "className": "vlist-s"
                        }}>{`​`}</span></span><span parentName="span" {...{
                        "className": "vlist-r"
                      }}><span parentName="span" {...{
                          "className": "vlist",
                          "style": {
                            "height": "0.15em"
                          }
                        }}><span parentName="span"></span></span></span></span></span></span><span parentName="span" {...{
                  "className": "mspace",
                  "style": {
                    "marginRight": "0.16666666666666666em"
                  }
                }}></span><span parentName="span" {...{
                  "className": "mop"
                }}>{`cos`}</span><span parentName="span" {...{
                  "className": "mopen"
                }}>{`(`}</span><span parentName="span" {...{
                  "className": "mord"
                }}>{`2`}</span><span parentName="span" {...{
                  "className": "mord mathnormal",
                  "style": {
                    "marginRight": "0.03588em"
                  }
                }}>{`π`}</span><span parentName="span" {...{
                  "className": "mord"
                }}><span parentName="span" {...{
                    "className": "mord mathnormal"
                  }}>{`b`}</span><span parentName="span" {...{
                    "className": "msupsub"
                  }}><span parentName="span" {...{
                      "className": "vlist-t vlist-t2"
                    }}><span parentName="span" {...{
                        "className": "vlist-r"
                      }}><span parentName="span" {...{
                          "className": "vlist",
                          "style": {
                            "height": "0.31166399999999994em"
                          }
                        }}><span parentName="span" {...{
                            "style": {
                              "top": "-2.5500000000000003em",
                              "marginLeft": "0em",
                              "marginRight": "0.05em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "2.7em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "sizing reset-size6 size3 mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mathnormal mtight"
                              }}>{`i`}</span></span></span></span><span parentName="span" {...{
                          "className": "vlist-s"
                        }}>{`​`}</span></span><span parentName="span" {...{
                        "className": "vlist-r"
                      }}><span parentName="span" {...{
                          "className": "vlist",
                          "style": {
                            "height": "0.15em"
                          }
                        }}><span parentName="span"></span></span></span></span></span></span><span parentName="span" {...{
                  "className": "mord mathnormal"
                }}>{`x`}</span><span parentName="span" {...{
                  "className": "mclose"
                }}>{`)`}</span></span></span></span></span></span></div>
    <p>{`We can use `}</p>
    <div {...{
      "className": "math math-display"
    }}><span parentName="div" {...{
        "className": "katex-display"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML",
              "display": "block"
            }}><semantics parentName="math"><mrow parentName="semantics"><msub parentName="mrow"><mi parentName="msub">{`a`}</mi><mi parentName="msub">{`i`}</mi></msub><mo parentName="mrow">{`=`}</mo><mn parentName="mrow">{`1`}</mn><mi parentName="mrow" {...{
                    "mathvariant": "normal"
                  }}>{`/`}</mi><msubsup parentName="mrow"><mi parentName="msubsup">{`b`}</mi><mi parentName="msubsup">{`i`}</mi><mi parentName="msubsup">{`p`}</mi></msubsup></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`a_i = 1 / b_i^p`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.58056em",
                  "verticalAlign": "-0.15em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord mathnormal"
                }}>{`a`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.31166399999999994em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.5500000000000003em",
                            "marginLeft": "0em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mathnormal mtight"
                            }}>{`i`}</span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.15em"
                        }
                      }}><span parentName="span"></span></span></span></span></span></span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2777777777777778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2777777777777778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "1.059164em",
                  "verticalAlign": "-0.276864em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}>{`1`}</span><span parentName="span" {...{
                "className": "mord"
              }}>{`/`}</span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord mathnormal"
                }}>{`b`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.7822999999999999em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.4231360000000004em",
                            "marginLeft": "0em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mathnormal mtight"
                            }}>{`i`}</span></span></span><span parentName="span" {...{
                          "style": {
                            "top": "-3.180908em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mathnormal mtight"
                            }}>{`p`}</span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.276864em"
                        }
                      }}><span parentName="span"></span></span></span></span></span></span></span></span></span></span></div>
    <p>{`to specify these weights. The weight decays for different `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`p`}</mi></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`p`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.625em",
                  "verticalAlign": "-0.19444em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`p`}</span></span></span></span></span>{` as
below:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "python"
    }}><pre parentName="div" {...{
        "className": "python"
      }}><code parentName="pre" {...{
          "className": "python"
        }}>{`plt`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`title`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"Spectral Weights"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`for`}</span>{` p `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`in`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0.5`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1.5`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token builtin"
          }}>{`float`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'inf'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
    a_s `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{` np`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`arange`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`8`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`__pow__`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`p`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
    plt`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`plot`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`a_s`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` label`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string-interpolation"
          }}><span parentName="span" {...{
              "className": "token string"
            }}>{`f"p=`}</span><span parentName="span" {...{
              "className": "token interpolation"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span>{`p`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span></span><span parentName="span" {...{
              "className": "token string"
            }}>{`"`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre></div>
    <figure>
      <img style={{
        "alignSelf": "center",
        "zoom": "20%"
      }} src="/47ffe3aa8e864fab145adbde4379e192/spectral_weights.png" image="None" styles="{'margin': '0.5em'}" width="None" height="None" />
    </figure>
    <h2>{`The Spectrum of Random Fourier Kernel`}</h2>
    <p>{`We can directly visualize the spectrum of this kernel. Now before we start
let's first remind ourselves of the basic concepts. `}</p>
    <p><strong parentName="p">{`definition 1:`}</strong>{` a similarity function `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`K`}</mi><mo parentName="mrow">{`:`}</mo><mi parentName="mrow">{`X`}</mi><mo parentName="mrow">{`↦`}</mo><mi parentName="mrow" {...{
                    "mathvariant": "double-struck"
                  }}>{`R`}</mi></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`K: X \\mapsto \\mathbb R`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.68333em",
                  "verticalAlign": "0em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.07153em"
                }
              }}>{`K`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2777777777777778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`:`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2777777777777778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.69433em",
                  "verticalAlign": "-0.011em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.07847em"
                }
              }}>{`X`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2777777777777778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`↦`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2777777777777778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.68889em",
                  "verticalAlign": "0em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathbb"
              }}>{`R`}</span></span></span></span></span>{` that is
`}<em parentName="p">{`continuous`}</em>{` and `}<em parentName="p">{`symmetric`}</em>{`, is called a `}<em parentName="p">{`kernel`}</em>{`.`}</p>
    <p><strong parentName="p">{`Mercer's Theorem`}</strong>{` `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`K`}</mi></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`K`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.68333em",
                  "verticalAlign": "0em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.07153em"
                }
              }}>{`K`}</span></span></span></span></span>{` is a `}<em parentName="p">{`kernel`}</em>{` `}<strong parentName="p">{`iff.`}</strong>{` for `}<em parentName="p">{`all`}</em>{` data
`}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`x`}</mi><mo parentName="mrow">{`∈`}</mo><mi parentName="mrow">{`X`}</mi></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`x\\in X`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.5782em",
                  "verticalAlign": "-0.0391em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`x`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2777777777777778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`∈`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2777777777777778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.68333em",
                  "verticalAlign": "0em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.07847em"
                }
              }}>{`X`}</span></span></span></span></span>{`, the Gramian matrix `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`M`}</mi></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`M`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.68333em",
                  "verticalAlign": "0em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.10903em"
                }
              }}>{`M`}</span></span></span></span></span>{` given by `}</p>
    <div {...{
      "className": "math math-display"
    }}><span parentName="div" {...{
        "className": "katex-display"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML",
              "display": "block"
            }}><semantics parentName="math"><mrow parentName="semantics"><msub parentName="mrow"><mi parentName="msub">{`G`}</mi><mrow parentName="msub"><mo parentName="mrow" {...{
                        "stretchy": "false"
                      }}>{`[`}</mo><mi parentName="mrow">{`i`}</mi><mo parentName="mrow" {...{
                        "separator": "true"
                      }}>{`,`}</mo><mi parentName="mrow">{`j`}</mi><mo parentName="mrow" {...{
                        "stretchy": "false"
                      }}>{`]`}</mo></mrow></msub><mo parentName="mrow">{`=`}</mo><mi parentName="mrow">{`K`}</mi><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`⟨`}</mo><msup parentName="mrow"><mi parentName="msup">{`x`}</mi><mi parentName="msup">{`i`}</mi></msup><mo parentName="mrow" {...{
                    "separator": "true"
                  }}>{`,`}</mo><msup parentName="mrow"><mi parentName="msup">{`x`}</mi><mi parentName="msup">{`j`}</mi></msup><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`⟩`}</mo></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`G_{[i, j]} = K\\langle x^i, x^j\\rangle`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "1.03853em",
                  "verticalAlign": "-0.3551999999999999em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord mathnormal"
                }}>{`G`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.34480000000000005em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.5198em",
                            "marginLeft": "0em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mopen mtight"
                              }}>{`[`}</span><span parentName="span" {...{
                                "className": "mord mathnormal mtight"
                              }}>{`i`}</span><span parentName="span" {...{
                                "className": "mpunct mtight"
                              }}>{`,`}</span><span parentName="span" {...{
                                "className": "mord mathnormal mtight",
                                "style": {
                                  "marginRight": "0.05724em"
                                }
                              }}>{`j`}</span><span parentName="span" {...{
                                "className": "mclose mtight"
                              }}>{`]`}</span></span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.3551999999999999em"
                        }
                      }}><span parentName="span"></span></span></span></span></span></span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2777777777777778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2777777777777778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "1.124664em",
                  "verticalAlign": "-0.25em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.07153em"
                }
              }}>{`K`}</span><span parentName="span" {...{
                "className": "mopen"
              }}>{`⟨`}</span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord mathnormal"
                }}>{`x`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.8746639999999999em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-3.113em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mathnormal mtight"
                            }}>{`i`}</span></span></span></span></span></span></span></span><span parentName="span" {...{
                "className": "mpunct"
              }}>{`,`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.16666666666666666em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord mathnormal"
                }}>{`x`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.874664em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-3.1130000000000004em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mathnormal mtight",
                              "style": {
                                "marginRight": "0.05724em"
                              }
                            }}>{`j`}</span></span></span></span></span></span></span></span><span parentName="span" {...{
                "className": "mclose"
              }}>{`⟩`}</span></span></span></span></span></div>
    <p>{`is positive semi-definite (PSD). This is called the `}<em parentName="p">{`kernel
trick`}</em>{`. For more precise formalism, refer to wikipedia.`}</p>
    <p>{`The neural tangent kernel (NTK) is similar to the `}<em parentName="p">{`conjugate kernel`}</em>{` (CK). The congjugate kernel looks at the neural network at initialization, whereas the neural tanget kernel looks at the `}<em parentName="p">{`gradient`}</em>{` of a network initialized around `}<em parentName="p">{`zero`}</em>{`. This is related to the first order Taylor expansion of the neural network function, and can be done by computing the Gramian matrix of the gradient vector around the intialization point `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><msub parentName="mrow"><mi parentName="msub">{`θ`}</mi><mn parentName="msub">{`0`}</mn></msub></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`\\theta_0`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.84444em",
                  "verticalAlign": "-0.15em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord mathnormal",
                  "style": {
                    "marginRight": "0.02778em"
                  }
                }}>{`θ`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.30110799999999993em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.5500000000000003em",
                            "marginLeft": "-0.02778em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}>{`0`}</span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.15em"
                        }
                      }}><span parentName="span"></span></span></span></span></span></span></span></span></span></span>{` of the network `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><msub parentName="mrow"><mi parentName="msub">{`f`}</mi><msub parentName="msub"><mi parentName="msub">{`θ`}</mi><mn parentName="msub">{`0`}</mn></msub></msub><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`(`}</mo><mi parentName="mrow">{`x`}</mi><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`)`}</mo></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`f_{\\theta_0}(x)`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "1.0001em",
                  "verticalAlign": "-0.2501em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord mathnormal",
                  "style": {
                    "marginRight": "0.10764em"
                  }
                }}>{`f`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.33610799999999996em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.5500000000000003em",
                            "marginLeft": "-0.10764em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mtight"
                              }}><span parentName="span" {...{
                                  "className": "mord mathnormal mtight",
                                  "style": {
                                    "marginRight": "0.02778em"
                                  }
                                }}>{`θ`}</span><span parentName="span" {...{
                                  "className": "msupsub"
                                }}><span parentName="span" {...{
                                    "className": "vlist-t vlist-t2"
                                  }}><span parentName="span" {...{
                                      "className": "vlist-r"
                                    }}><span parentName="span" {...{
                                        "className": "vlist",
                                        "style": {
                                          "height": "0.31731428571428577em"
                                        }
                                      }}><span parentName="span" {...{
                                          "style": {
                                            "top": "-2.357em",
                                            "marginLeft": "-0.02778em",
                                            "marginRight": "0.07142857142857144em"
                                          }
                                        }}><span parentName="span" {...{
                                            "className": "pstrut",
                                            "style": {
                                              "height": "2.5em"
                                            }
                                          }}></span><span parentName="span" {...{
                                            "className": "sizing reset-size3 size1 mtight"
                                          }}><span parentName="span" {...{
                                              "className": "mord mtight"
                                            }}>{`0`}</span></span></span></span><span parentName="span" {...{
                                        "className": "vlist-s"
                                      }}>{`​`}</span></span><span parentName="span" {...{
                                      "className": "vlist-r"
                                    }}><span parentName="span" {...{
                                        "className": "vlist",
                                        "style": {
                                          "height": "0.143em"
                                        }
                                      }}><span parentName="span"></span></span></span></span></span></span></span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.2501em"
                        }
                      }}><span parentName="span"></span></span></span></span></span></span><span parentName="span" {...{
                "className": "mopen"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`x`}</span><span parentName="span" {...{
                "className": "mclose"
              }}>{`)`}</span></span></span></span></span>{`:`}</p>
    <div {...{
      "className": "math math-display"
    }}><span parentName="div" {...{
        "className": "katex-display"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML",
              "display": "block"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`N`}</mi><mi parentName="mrow">{`T`}</mi><mi parentName="mrow">{`K`}</mi><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`⟨`}</mo><msup parentName="mrow"><mi parentName="msup">{`x`}</mi><mi parentName="msup">{`i`}</mi></msup><mo parentName="mrow" {...{
                    "separator": "true"
                  }}>{`,`}</mo><msup parentName="mrow"><mi parentName="msup">{`x`}</mi><mi parentName="msup">{`j`}</mi></msup><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`⟩`}</mo><mo parentName="mrow">{`=`}</mo><msub parentName="mrow"><mi parentName="msub" {...{
                      "mathvariant": "normal"
                    }}>{`∇`}</mi><mi parentName="msub">{`θ`}</mi></msub><mi parentName="mrow">{`f`}</mi><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`(`}</mo><msup parentName="mrow"><mi parentName="msup">{`x`}</mi><mi parentName="msup">{`i`}</mi></msup><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`)`}</mo><msubsup parentName="mrow"><mi parentName="msubsup" {...{
                      "mathvariant": "normal"
                    }}>{`∇`}</mi><mi parentName="msubsup">{`θ`}</mi><mi parentName="msubsup">{`T`}</mi></msubsup><mi parentName="mrow">{`f`}</mi><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`(`}</mo><msup parentName="mrow"><mi parentName="msup">{`x`}</mi><mi parentName="msup">{`j`}</mi></msup><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`)`}</mo></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`NTK\\langle x^i, x^j \\rangle = \\nabla_\\theta f(x^i) \\nabla^T_\\theta f(x^j)`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "1.124664em",
                  "verticalAlign": "-0.25em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.10903em"
                }
              }}>{`N`}</span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.13889em"
                }
              }}>{`T`}</span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.07153em"
                }
              }}>{`K`}</span><span parentName="span" {...{
                "className": "mopen"
              }}>{`⟨`}</span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord mathnormal"
                }}>{`x`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.8746639999999999em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-3.113em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mathnormal mtight"
                            }}>{`i`}</span></span></span></span></span></span></span></span><span parentName="span" {...{
                "className": "mpunct"
              }}>{`,`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.16666666666666666em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord mathnormal"
                }}>{`x`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.874664em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-3.1130000000000004em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mathnormal mtight",
                              "style": {
                                "marginRight": "0.05724em"
                              }
                            }}>{`j`}</span></span></span></span></span></span></span></span><span parentName="span" {...{
                "className": "mclose"
              }}>{`⟩`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2777777777777778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2777777777777778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "1.1413309999999999em",
                  "verticalAlign": "-0.25em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord"
                }}>{`∇`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.33610799999999996em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.5500000000000003em",
                            "marginLeft": "0em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mathnormal mtight",
                              "style": {
                                "marginRight": "0.02778em"
                              }
                            }}>{`θ`}</span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.15em"
                        }
                      }}><span parentName="span"></span></span></span></span></span></span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.10764em"
                }
              }}>{`f`}</span><span parentName="span" {...{
                "className": "mopen"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord mathnormal"
                }}>{`x`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.8746639999999999em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-3.113em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mathnormal mtight"
                            }}>{`i`}</span></span></span></span></span></span></span></span><span parentName="span" {...{
                "className": "mclose"
              }}>{`)`}</span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord"
                }}>{`∇`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.8913309999999999em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.4530000000000003em",
                            "marginLeft": "0em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mathnormal mtight",
                              "style": {
                                "marginRight": "0.02778em"
                              }
                            }}>{`θ`}</span></span></span><span parentName="span" {...{
                          "style": {
                            "top": "-3.113em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mathnormal mtight",
                              "style": {
                                "marginRight": "0.13889em"
                              }
                            }}>{`T`}</span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.247em"
                        }
                      }}><span parentName="span"></span></span></span></span></span></span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.10764em"
                }
              }}>{`f`}</span><span parentName="span" {...{
                "className": "mopen"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord mathnormal"
                }}>{`x`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.874664em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-3.1130000000000004em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mathnormal mtight",
                              "style": {
                                "marginRight": "0.05724em"
                              }
                            }}>{`j`}</span></span></span></span></span></span></span></span><span parentName="span" {...{
                "className": "mclose"
              }}>{`)`}</span></span></span></span></span></div>
    <p>{`There are two important details:`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`The gradient vector `}<span parentName="p" {...{
            "className": "math math-inline"
          }}><span parentName="span" {...{
              "className": "katex"
            }}><span parentName="span" {...{
                "className": "katex-mathml"
              }}><math parentName="span" {...{
                  "xmlns": "http://www.w3.org/1998/Math/MathML"
                }}><semantics parentName="math"><mrow parentName="semantics"><msub parentName="mrow"><mi parentName="msub" {...{
                          "mathvariant": "normal"
                        }}>{`∇`}</mi><mi parentName="msub">{`θ`}</mi></msub><mi parentName="mrow">{`f`}</mi></mrow><annotation parentName="semantics" {...{
                      "encoding": "application/x-tex"
                    }}>{`\\nabla_\\theta f`}</annotation></semantics></math></span><span parentName="span" {...{
                "className": "katex-html",
                "aria-hidden": "true"
              }}><span parentName="span" {...{
                  "className": "base"
                }}><span parentName="span" {...{
                    "className": "strut",
                    "style": {
                      "height": "0.8888799999999999em",
                      "verticalAlign": "-0.19444em"
                    }
                  }}></span><span parentName="span" {...{
                    "className": "mord"
                  }}><span parentName="span" {...{
                      "className": "mord"
                    }}>{`∇`}</span><span parentName="span" {...{
                      "className": "msupsub"
                    }}><span parentName="span" {...{
                        "className": "vlist-t vlist-t2"
                      }}><span parentName="span" {...{
                          "className": "vlist-r"
                        }}><span parentName="span" {...{
                            "className": "vlist",
                            "style": {
                              "height": "0.33610799999999996em"
                            }
                          }}><span parentName="span" {...{
                              "style": {
                                "top": "-2.5500000000000003em",
                                "marginLeft": "0em",
                                "marginRight": "0.05em"
                              }
                            }}><span parentName="span" {...{
                                "className": "pstrut",
                                "style": {
                                  "height": "2.7em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "sizing reset-size6 size3 mtight"
                              }}><span parentName="span" {...{
                                  "className": "mord mathnormal mtight",
                                  "style": {
                                    "marginRight": "0.02778em"
                                  }
                                }}>{`θ`}</span></span></span></span><span parentName="span" {...{
                            "className": "vlist-s"
                          }}>{`​`}</span></span><span parentName="span" {...{
                          "className": "vlist-r"
                        }}><span parentName="span" {...{
                            "className": "vlist",
                            "style": {
                              "height": "0.15em"
                            }
                          }}><span parentName="span"></span></span></span></span></span></span><span parentName="span" {...{
                    "className": "mord mathnormal",
                    "style": {
                      "marginRight": "0.10764em"
                    }
                  }}>{`f`}</span></span></span></span></span>{` needs to be normalized, so that for the same datapoint `}<span parentName="p" {...{
            "className": "math math-inline"
          }}><span parentName="span" {...{
              "className": "katex"
            }}><span parentName="span" {...{
                "className": "katex-mathml"
              }}><math parentName="span" {...{
                  "xmlns": "http://www.w3.org/1998/Math/MathML"
                }}><semantics parentName="math"><mrow parentName="semantics"><msub parentName="mrow"><mi parentName="msub">{`x`}</mi><mi parentName="msub">{`i`}</mi></msub><mo parentName="mrow">{`=`}</mo><msub parentName="mrow"><mi parentName="msub">{`x`}</mi><mi parentName="msub">{`j`}</mi></msub></mrow><annotation parentName="semantics" {...{
                      "encoding": "application/x-tex"
                    }}>{`x_i = x_j`}</annotation></semantics></math></span><span parentName="span" {...{
                "className": "katex-html",
                "aria-hidden": "true"
              }}><span parentName="span" {...{
                  "className": "base"
                }}><span parentName="span" {...{
                    "className": "strut",
                    "style": {
                      "height": "0.58056em",
                      "verticalAlign": "-0.15em"
                    }
                  }}></span><span parentName="span" {...{
                    "className": "mord"
                  }}><span parentName="span" {...{
                      "className": "mord mathnormal"
                    }}>{`x`}</span><span parentName="span" {...{
                      "className": "msupsub"
                    }}><span parentName="span" {...{
                        "className": "vlist-t vlist-t2"
                      }}><span parentName="span" {...{
                          "className": "vlist-r"
                        }}><span parentName="span" {...{
                            "className": "vlist",
                            "style": {
                              "height": "0.31166399999999994em"
                            }
                          }}><span parentName="span" {...{
                              "style": {
                                "top": "-2.5500000000000003em",
                                "marginLeft": "0em",
                                "marginRight": "0.05em"
                              }
                            }}><span parentName="span" {...{
                                "className": "pstrut",
                                "style": {
                                  "height": "2.7em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "sizing reset-size6 size3 mtight"
                              }}><span parentName="span" {...{
                                  "className": "mord mathnormal mtight"
                                }}>{`i`}</span></span></span></span><span parentName="span" {...{
                            "className": "vlist-s"
                          }}>{`​`}</span></span><span parentName="span" {...{
                          "className": "vlist-r"
                        }}><span parentName="span" {...{
                            "className": "vlist",
                            "style": {
                              "height": "0.15em"
                            }
                          }}><span parentName="span"></span></span></span></span></span></span><span parentName="span" {...{
                    "className": "mspace",
                    "style": {
                      "marginRight": "0.2777777777777778em"
                    }
                  }}></span><span parentName="span" {...{
                    "className": "mrel"
                  }}>{`=`}</span><span parentName="span" {...{
                    "className": "mspace",
                    "style": {
                      "marginRight": "0.2777777777777778em"
                    }
                  }}></span></span><span parentName="span" {...{
                  "className": "base"
                }}><span parentName="span" {...{
                    "className": "strut",
                    "style": {
                      "height": "0.716668em",
                      "verticalAlign": "-0.286108em"
                    }
                  }}></span><span parentName="span" {...{
                    "className": "mord"
                  }}><span parentName="span" {...{
                      "className": "mord mathnormal"
                    }}>{`x`}</span><span parentName="span" {...{
                      "className": "msupsub"
                    }}><span parentName="span" {...{
                        "className": "vlist-t vlist-t2"
                      }}><span parentName="span" {...{
                          "className": "vlist-r"
                        }}><span parentName="span" {...{
                            "className": "vlist",
                            "style": {
                              "height": "0.311664em"
                            }
                          }}><span parentName="span" {...{
                              "style": {
                                "top": "-2.5500000000000003em",
                                "marginLeft": "0em",
                                "marginRight": "0.05em"
                              }
                            }}><span parentName="span" {...{
                                "className": "pstrut",
                                "style": {
                                  "height": "2.7em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "sizing reset-size6 size3 mtight"
                              }}><span parentName="span" {...{
                                  "className": "mord mathnormal mtight",
                                  "style": {
                                    "marginRight": "0.05724em"
                                  }
                                }}>{`j`}</span></span></span></span><span parentName="span" {...{
                            "className": "vlist-s"
                          }}>{`​`}</span></span><span parentName="span" {...{
                          "className": "vlist-r"
                        }}><span parentName="span" {...{
                            "className": "vlist",
                            "style": {
                              "height": "0.286108em"
                            }
                          }}><span parentName="span"></span></span></span></span></span></span></span></span></span></span>{`, the NTK kernel produces the identity.`}</p>
        <p parentName="li">{`This is the reason behind the line below`}</p>
        <div parentName="li" {...{
          "className": "gatsby-highlight",
          "data-language": "python"
        }}><pre parentName="div" {...{
            "className": "python"
          }}><code parentName="pre" {...{
              "className": "python"
            }}>{`grad`}<span parentName="code" {...{
                "className": "token punctuation"
              }}>{`.`}</span>{`append`}<span parentName="code" {...{
                "className": "token punctuation"
              }}>{`(`}</span>{`grad_vec `}<span parentName="code" {...{
                "className": "token operator"
              }}>{`/`}</span>{` np`}<span parentName="code" {...{
                "className": "token punctuation"
              }}>{`.`}</span>{`linalg`}<span parentName="code" {...{
                "className": "token punctuation"
              }}>{`.`}</span>{`norm`}<span parentName="code" {...{
                "className": "token punctuation"
              }}>{`(`}</span>{`grad_vec`}<span parentName="code" {...{
                "className": "token punctuation"
              }}>{`)`}</span><span parentName="code" {...{
                "className": "token punctuation"
              }}>{`)`}</span></code></pre></div>
      </li>
      <li parentName="ol">
        <p parentName="li">{`The Gram matrix for one network instantiation can be quite noisy. We need to average over multiple instantiations.`}</p>
        <div parentName="li" {...{
          "className": "gatsby-highlight",
          "data-language": "python"
        }}><pre parentName="div" {...{
            "className": "python"
          }}><code parentName="pre" {...{
              "className": "python"
            }}><span parentName="code" {...{
                "className": "token keyword"
              }}>{`def`}</span>{` `}<span parentName="code" {...{
                "className": "token function"
              }}>{`get_ntk`}</span><span parentName="code" {...{
                "className": "token punctuation"
              }}>{`(`}</span>{`net`}<span parentName="code" {...{
                "className": "token punctuation"
              }}>{`,`}</span>{` xs`}<span parentName="code" {...{
                "className": "token punctuation"
              }}>{`)`}</span><span parentName="code" {...{
                "className": "token punctuation"
              }}>{`:`}</span>{`
    grad `}<span parentName="code" {...{
                "className": "token operator"
              }}>{`=`}</span>{` `}<span parentName="code" {...{
                "className": "token punctuation"
              }}>{`[`}</span><span parentName="code" {...{
                "className": "token punctuation"
              }}>{`]`}</span>{`
    out `}<span parentName="code" {...{
                "className": "token operator"
              }}>{`=`}</span>{` net`}<span parentName="code" {...{
                "className": "token punctuation"
              }}>{`(`}</span>{`torch`}<span parentName="code" {...{
                "className": "token punctuation"
              }}>{`.`}</span>{`FloatTensor`}<span parentName="code" {...{
                "className": "token punctuation"
              }}>{`(`}</span>{`xs`}<span parentName="code" {...{
                "className": "token punctuation"
              }}>{`)`}</span><span parentName="code" {...{
                "className": "token punctuation"
              }}>{`[`}</span><span parentName="code" {...{
                "className": "token punctuation"
              }}>{`:`}</span><span parentName="code" {...{
                "className": "token punctuation"
              }}>{`,`}</span>{` `}<span parentName="code" {...{
                "className": "token boolean"
              }}>{`None`}</span><span parentName="code" {...{
                "className": "token punctuation"
              }}>{`]`}</span><span parentName="code" {...{
                "className": "token punctuation"
              }}>{`)`}</span>{`
    `}<span parentName="code" {...{
                "className": "token keyword"
              }}>{`for`}</span>{` o `}<span parentName="code" {...{
                "className": "token keyword"
              }}>{`in`}</span>{` tqdm`}<span parentName="code" {...{
                "className": "token punctuation"
              }}>{`(`}</span>{`out`}<span parentName="code" {...{
                "className": "token punctuation"
              }}>{`,`}</span>{` desc`}<span parentName="code" {...{
                "className": "token operator"
              }}>{`=`}</span><span parentName="code" {...{
                "className": "token string"
              }}>{`"NTK"`}</span><span parentName="code" {...{
                "className": "token punctuation"
              }}>{`,`}</span>{` leave`}<span parentName="code" {...{
                "className": "token operator"
              }}>{`=`}</span><span parentName="code" {...{
                "className": "token boolean"
              }}>{`False`}</span><span parentName="code" {...{
                "className": "token punctuation"
              }}>{`)`}</span><span parentName="code" {...{
                "className": "token punctuation"
              }}>{`:`}</span>{`
        net`}<span parentName="code" {...{
                "className": "token punctuation"
              }}>{`.`}</span>{`zero_grad`}<span parentName="code" {...{
                "className": "token punctuation"
              }}>{`(`}</span><span parentName="code" {...{
                "className": "token punctuation"
              }}>{`)`}</span>{`
        o`}<span parentName="code" {...{
                "className": "token punctuation"
              }}>{`.`}</span>{`backward`}<span parentName="code" {...{
                "className": "token punctuation"
              }}>{`(`}</span>{`retain_graph`}<span parentName="code" {...{
                "className": "token operator"
              }}>{`=`}</span><span parentName="code" {...{
                "className": "token boolean"
              }}>{`True`}</span><span parentName="code" {...{
                "className": "token punctuation"
              }}>{`)`}</span>{`
        grad_vec `}<span parentName="code" {...{
                "className": "token operator"
              }}>{`=`}</span>{` torch`}<span parentName="code" {...{
                "className": "token punctuation"
              }}>{`.`}</span>{`cat`}<span parentName="code" {...{
                "className": "token punctuation"
              }}>{`(`}</span><span parentName="code" {...{
                "className": "token punctuation"
              }}>{`[`}</span>{`p`}<span parentName="code" {...{
                "className": "token punctuation"
              }}>{`.`}</span>{`grad`}<span parentName="code" {...{
                "className": "token punctuation"
              }}>{`.`}</span>{`view`}<span parentName="code" {...{
                "className": "token punctuation"
              }}>{`(`}</span><span parentName="code" {...{
                "className": "token operator"
              }}>{`-`}</span><span parentName="code" {...{
                "className": "token number"
              }}>{`1`}</span><span parentName="code" {...{
                "className": "token punctuation"
              }}>{`)`}</span>{` `}<span parentName="code" {...{
                "className": "token keyword"
              }}>{`for`}</span>{` p `}<span parentName="code" {...{
                "className": "token keyword"
              }}>{`in`}</span>{` net`}<span parentName="code" {...{
                "className": "token punctuation"
              }}>{`.`}</span>{`parameters`}<span parentName="code" {...{
                "className": "token punctuation"
              }}>{`(`}</span><span parentName="code" {...{
                "className": "token punctuation"
              }}>{`)`}</span>{` `}<span parentName="code" {...{
                "className": "token keyword"
              }}>{`if`}</span>{` p`}<span parentName="code" {...{
                "className": "token punctuation"
              }}>{`.`}</span>{`grad `}<span parentName="code" {...{
                "className": "token keyword"
              }}>{`is`}</span>{` `}<span parentName="code" {...{
                "className": "token keyword"
              }}>{`not`}</span>{` `}<span parentName="code" {...{
                "className": "token boolean"
              }}>{`None`}</span><span parentName="code" {...{
                "className": "token punctuation"
              }}>{`]`}</span><span parentName="code" {...{
                "className": "token punctuation"
              }}>{`)`}</span><span parentName="code" {...{
                "className": "token punctuation"
              }}>{`.`}</span>{`numpy`}<span parentName="code" {...{
                "className": "token punctuation"
              }}>{`(`}</span><span parentName="code" {...{
                "className": "token punctuation"
              }}>{`)`}</span>{`
        grad`}<span parentName="code" {...{
                "className": "token punctuation"
              }}>{`.`}</span>{`append`}<span parentName="code" {...{
                "className": "token punctuation"
              }}>{`(`}</span>{`grad_vec `}<span parentName="code" {...{
                "className": "token operator"
              }}>{`/`}</span>{` np`}<span parentName="code" {...{
                "className": "token punctuation"
              }}>{`.`}</span>{`linalg`}<span parentName="code" {...{
                "className": "token punctuation"
              }}>{`.`}</span>{`norm`}<span parentName="code" {...{
                "className": "token punctuation"
              }}>{`(`}</span>{`grad_vec`}<span parentName="code" {...{
                "className": "token punctuation"
              }}>{`)`}</span><span parentName="code" {...{
                "className": "token punctuation"
              }}>{`)`}</span>{`
        net`}<span parentName="code" {...{
                "className": "token punctuation"
              }}>{`.`}</span>{`zero_grad`}<span parentName="code" {...{
                "className": "token punctuation"
              }}>{`(`}</span><span parentName="code" {...{
                "className": "token punctuation"
              }}>{`)`}</span>{`

    grad `}<span parentName="code" {...{
                "className": "token operator"
              }}>{`=`}</span>{` np`}<span parentName="code" {...{
                "className": "token punctuation"
              }}>{`.`}</span>{`stack`}<span parentName="code" {...{
                "className": "token punctuation"
              }}>{`(`}</span>{`grad`}<span parentName="code" {...{
                "className": "token punctuation"
              }}>{`)`}</span>{`
    gram_matrix `}<span parentName="code" {...{
                "className": "token operator"
              }}>{`=`}</span>{` grad @ grad`}<span parentName="code" {...{
                "className": "token punctuation"
              }}>{`.`}</span>{`T
    `}<span parentName="code" {...{
                "className": "token keyword"
              }}>{`return`}</span>{` gram_matrix`}</code></pre></div>
        <div parentName="li" {...{
          "className": "gatsby-highlight",
          "data-language": "python"
        }}><pre parentName="div" {...{
            "className": "python"
          }}><code parentName="pre" {...{
              "className": "python"
            }}>{`xs `}<span parentName="code" {...{
                "className": "token operator"
              }}>{`=`}</span>{` np`}<span parentName="code" {...{
                "className": "token punctuation"
              }}>{`.`}</span>{`linspace`}<span parentName="code" {...{
                "className": "token punctuation"
              }}>{`(`}</span><span parentName="code" {...{
                "className": "token operator"
              }}>{`-`}</span><span parentName="code" {...{
                "className": "token number"
              }}>{`0.5`}</span><span parentName="code" {...{
                "className": "token punctuation"
              }}>{`,`}</span>{` `}<span parentName="code" {...{
                "className": "token number"
              }}>{`0.5`}</span><span parentName="code" {...{
                "className": "token punctuation"
              }}>{`,`}</span>{` `}<span parentName="code" {...{
                "className": "token number"
              }}>{`32`}</span><span parentName="code" {...{
                "className": "token punctuation"
              }}>{`)`}</span>{`

`}<span parentName="code" {...{
                "className": "token keyword"
              }}>{`for`}</span>{` p `}<span parentName="code" {...{
                "className": "token keyword"
              }}>{`in`}</span>{` tqdm`}<span parentName="code" {...{
                "className": "token punctuation"
              }}>{`(`}</span><span parentName="code" {...{
                "className": "token punctuation"
              }}>{`[`}</span><span parentName="code" {...{
                "className": "token number"
              }}>{`0`}</span><span parentName="code" {...{
                "className": "token punctuation"
              }}>{`,`}</span>{` `}<span parentName="code" {...{
                "className": "token number"
              }}>{`0.5`}</span><span parentName="code" {...{
                "className": "token punctuation"
              }}>{`,`}</span>{` `}<span parentName="code" {...{
                "className": "token number"
              }}>{`1`}</span><span parentName="code" {...{
                "className": "token punctuation"
              }}>{`,`}</span>{` `}<span parentName="code" {...{
                "className": "token number"
              }}>{`1.5`}</span><span parentName="code" {...{
                "className": "token punctuation"
              }}>{`,`}</span>{` `}<span parentName="code" {...{
                "className": "token number"
              }}>{`2`}</span><span parentName="code" {...{
                "className": "token punctuation"
              }}>{`,`}</span>{` `}<span parentName="code" {...{
                "className": "token builtin"
              }}>{`float`}</span><span parentName="code" {...{
                "className": "token punctuation"
              }}>{`(`}</span><span parentName="code" {...{
                "className": "token string"
              }}>{`'inf'`}</span><span parentName="code" {...{
                "className": "token punctuation"
              }}>{`)`}</span><span parentName="code" {...{
                "className": "token punctuation"
              }}>{`]`}</span><span parentName="code" {...{
                "className": "token punctuation"
              }}>{`,`}</span>{` desc`}<span parentName="code" {...{
                "className": "token operator"
              }}>{`=`}</span><span parentName="code" {...{
                "className": "token string"
              }}>{`"kernels"`}</span><span parentName="code" {...{
                "className": "token punctuation"
              }}>{`)`}</span><span parentName="code" {...{
                "className": "token punctuation"
              }}>{`:`}</span>{`
    ntk_kernel `}<span parentName="code" {...{
                "className": "token operator"
              }}>{`=`}</span>{` `}<span parentName="code" {...{
                "className": "token number"
              }}>{`0`}</span>{`  `}<span parentName="code" {...{
                "className": "token comment"
              }}>{`# average from ten networks`}</span>{`
    `}<span parentName="code" {...{
                "className": "token keyword"
              }}>{`for`}</span>{` i `}<span parentName="code" {...{
                "className": "token keyword"
              }}>{`in`}</span>{` trange`}<span parentName="code" {...{
                "className": "token punctuation"
              }}>{`(`}</span><span parentName="code" {...{
                "className": "token number"
              }}>{`10`}</span><span parentName="code" {...{
                "className": "token punctuation"
              }}>{`,`}</span>{` desc`}<span parentName="code" {...{
                "className": "token operator"
              }}>{`=`}</span><span parentName="code" {...{
                "className": "token string"
              }}>{`"averaging networks"`}</span><span parentName="code" {...{
                "className": "token punctuation"
              }}>{`,`}</span>{` leave`}<span parentName="code" {...{
                "className": "token operator"
              }}>{`=`}</span><span parentName="code" {...{
                "className": "token boolean"
              }}>{`False`}</span><span parentName="code" {...{
                "className": "token punctuation"
              }}>{`)`}</span><span parentName="code" {...{
                "className": "token punctuation"
              }}>{`:`}</span>{`
        net `}<span parentName="code" {...{
                "className": "token operator"
              }}>{`=`}</span>{` nn`}<span parentName="code" {...{
                "className": "token punctuation"
              }}>{`.`}</span>{`Sequential`}<span parentName="code" {...{
                "className": "token punctuation"
              }}>{`(`}</span>{`FF`}<span parentName="code" {...{
                "className": "token punctuation"
              }}>{`(`}</span><span parentName="code" {...{
                "className": "token number"
              }}>{`16`}</span><span parentName="code" {...{
                "className": "token punctuation"
              }}>{`,`}</span>{` p`}<span parentName="code" {...{
                "className": "token punctuation"
              }}>{`)`}</span><span parentName="code" {...{
                "className": "token punctuation"
              }}>{`,`}</span>{` MLP`}<span parentName="code" {...{
                "className": "token punctuation"
              }}>{`(`}</span><span parentName="code" {...{
                "className": "token number"
              }}>{`32`}</span><span parentName="code" {...{
                "className": "token punctuation"
              }}>{`,`}</span>{` `}<span parentName="code" {...{
                "className": "token number"
              }}>{`1024`}</span><span parentName="code" {...{
                "className": "token punctuation"
              }}>{`,`}</span>{` `}<span parentName="code" {...{
                "className": "token number"
              }}>{`4`}</span><span parentName="code" {...{
                "className": "token punctuation"
              }}>{`,`}</span>{` `}<span parentName="code" {...{
                "className": "token number"
              }}>{`1`}</span><span parentName="code" {...{
                "className": "token punctuation"
              }}>{`)`}</span><span parentName="code" {...{
                "className": "token punctuation"
              }}>{`)`}</span>{`
        ntk_kernel `}<span parentName="code" {...{
                "className": "token operator"
              }}>{`+=`}</span>{` `}<span parentName="code" {...{
                "className": "token number"
              }}>{`0.1`}</span>{` `}<span parentName="code" {...{
                "className": "token operator"
              }}>{`*`}</span>{` get_ntk`}<span parentName="code" {...{
                "className": "token punctuation"
              }}>{`(`}</span>{`net`}<span parentName="code" {...{
                "className": "token punctuation"
              }}>{`,`}</span>{` xs`}<span parentName="code" {...{
                "className": "token punctuation"
              }}>{`)`}</span>{`

    plt`}<span parentName="code" {...{
                "className": "token punctuation"
              }}>{`.`}</span>{`figure`}<span parentName="code" {...{
                "className": "token punctuation"
              }}>{`(`}</span><span parentName="code" {...{
                "className": "token string"
              }}>{`'cross section'`}</span><span parentName="code" {...{
                "className": "token punctuation"
              }}>{`)`}</span>{`
    plt`}<span parentName="code" {...{
                "className": "token punctuation"
              }}>{`.`}</span>{`plot`}<span parentName="code" {...{
                "className": "token punctuation"
              }}>{`(`}</span>{`ntk_kernel`}<span parentName="code" {...{
                "className": "token punctuation"
              }}>{`[`}</span><span parentName="code" {...{
                "className": "token number"
              }}>{`16`}</span><span parentName="code" {...{
                "className": "token punctuation"
              }}>{`]`}</span><span parentName="code" {...{
                "className": "token punctuation"
              }}>{`,`}</span>{` label`}<span parentName="code" {...{
                "className": "token operator"
              }}>{`=`}</span><span parentName="code" {...{
                "className": "token string-interpolation"
              }}><span parentName="span" {...{
                  "className": "token string"
                }}>{`f'p=`}</span><span parentName="span" {...{
                  "className": "token interpolation"
                }}><span parentName="span" {...{
                    "className": "token punctuation"
                  }}>{`{`}</span>{`p`}<span parentName="span" {...{
                    "className": "token punctuation"
                  }}>{`}`}</span></span><span parentName="span" {...{
                  "className": "token string"
                }}>{`'`}</span></span><span parentName="code" {...{
                "className": "token punctuation"
              }}>{`)`}</span>{`

    plt`}<span parentName="code" {...{
                "className": "token punctuation"
              }}>{`.`}</span>{`figure`}<span parentName="code" {...{
                "className": "token punctuation"
              }}>{`(`}</span><span parentName="code" {...{
                "className": "token string"
              }}>{`'spectrum'`}</span><span parentName="code" {...{
                "className": "token punctuation"
              }}>{`)`}</span>{`
    fft `}<span parentName="code" {...{
                "className": "token operator"
              }}>{`=`}</span>{` np`}<span parentName="code" {...{
                "className": "token punctuation"
              }}>{`.`}</span>{`fft`}<span parentName="code" {...{
                "className": "token punctuation"
              }}>{`.`}</span>{`fft`}<span parentName="code" {...{
                "className": "token punctuation"
              }}>{`(`}</span>{`ntk_kernel`}<span parentName="code" {...{
                "className": "token punctuation"
              }}>{`[`}</span><span parentName="code" {...{
                "className": "token number"
              }}>{`16`}</span><span parentName="code" {...{
                "className": "token punctuation"
              }}>{`]`}</span><span parentName="code" {...{
                "className": "token punctuation"
              }}>{`)`}</span>{`
    plt`}<span parentName="code" {...{
                "className": "token punctuation"
              }}>{`.`}</span>{`plot`}<span parentName="code" {...{
                "className": "token punctuation"
              }}>{`(`}</span>{`np`}<span parentName="code" {...{
                "className": "token punctuation"
              }}>{`.`}</span>{`fft`}<span parentName="code" {...{
                "className": "token punctuation"
              }}>{`.`}</span>{`fftshift`}<span parentName="code" {...{
                "className": "token punctuation"
              }}>{`(`}</span>{`fft`}<span parentName="code" {...{
                "className": "token punctuation"
              }}>{`)`}</span><span parentName="code" {...{
                "className": "token punctuation"
              }}>{`.`}</span>{`__abs__`}<span parentName="code" {...{
                "className": "token punctuation"
              }}>{`(`}</span><span parentName="code" {...{
                "className": "token punctuation"
              }}>{`)`}</span><span parentName="code" {...{
                "className": "token punctuation"
              }}>{`,`}</span>{` label`}<span parentName="code" {...{
                "className": "token operator"
              }}>{`=`}</span><span parentName="code" {...{
                "className": "token string-interpolation"
              }}><span parentName="span" {...{
                  "className": "token string"
                }}>{`f'p=`}</span><span parentName="span" {...{
                  "className": "token interpolation"
                }}><span parentName="span" {...{
                    "className": "token punctuation"
                  }}>{`{`}</span>{`p`}<span parentName="span" {...{
                    "className": "token punctuation"
                  }}>{`}`}</span></span><span parentName="span" {...{
                  "className": "token string"
                }}>{`'`}</span></span><span parentName="code" {...{
                "className": "token punctuation"
              }}>{`)`}</span></code></pre></div>
        <div parentName="li" {...{
          "className": "gatsby-highlight",
          "data-language": "python"
        }}><pre parentName="div" {...{
            "className": "python"
          }}><code parentName="pre" {...{
              "className": "python"
            }}>{`plt`}<span parentName="code" {...{
                "className": "token punctuation"
              }}>{`.`}</span>{`imshow`}<span parentName="code" {...{
                "className": "token punctuation"
              }}>{`(`}</span>{`ntk_kernel`}<span parentName="code" {...{
                "className": "token punctuation"
              }}>{`,`}</span>{` cmap`}<span parentName="code" {...{
                "className": "token operator"
              }}>{`=`}</span><span parentName="code" {...{
                "className": "token string"
              }}>{`'inferno'`}</span><span parentName="code" {...{
                "className": "token punctuation"
              }}>{`)`}</span></code></pre></div>
        <table parentName="li">
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": "center"
              }}><strong parentName="th">{`Kernel`}</strong></th>
              <th parentName="tr" {...{
                "align": "center"
              }}><strong parentName="th">{`Cross Section`}</strong></th>
              <th parentName="tr" {...{
                "align": "center"
              }}><strong parentName="th">{`Spectrum`}</strong></th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": "center"
              }}><img style={{
                  "alignSelf": "center",
                  "zoom": "18%"
                }} src="/19c6eca2af10d16e4fe4e41f75fe9c56/fourier_features_ntk.png" image="None" styles="{'margin': '0.5em'}" width="None" height="None" fig="Figure(640x480)" /></td>
              <td parentName="tr" {...{
                "align": "center"
              }}><img style={{
                  "alignSelf": "center",
                  "zoom": "18%"
                }} src="/529881da2a0ab23e233f5e0ba7c3044a/fourier_features_ntk_cross.png" image="None" styles="{'margin': '0.5em'}" width="None" height="None" /></td>
              <td parentName="tr" {...{
                "align": "center"
              }}><img style={{
                  "alignSelf": "center",
                  "zoom": "18%"
                }} src="/10562c1def10ed778f5f4c0d917f5701/fourier_features_ntk_spectrum.png" image="None" styles="{'margin': '0.5em'}" width="None" height="None" /></td>
            </tr>
          </tbody>
        </table>
      </li>
    </ol>
    <h2>{`The Band-limited Fourier Features`}</h2>
    <p>{`An important feature of our implementation above, is that the
fourier features are band-limited on the top. For `}<code parentName="p" {...{
        "className": "text"
      }}>{`band_limit=16`}</code>{`,
if we visualize `}<em parentName="p">{`beyond`}</em>{` the 8th octave, we would see the spectrum
encounter a sharp drop. `}</p>
    <p>{`This happens because we are effectively clipping the higher order
components`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "python"
    }}><pre parentName="div" {...{
        "className": "python"
      }}><code parentName="pre" {...{
          "className": "python"
        }}>{`plt`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`figure`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"Full A's"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`for`}</span>{` p `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`in`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0.5`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1.5`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token builtin"
          }}>{`float`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'inf'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
    ff `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` FF`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`16`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` p`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
    full_a `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` np`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`zeros`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`32`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
    full_a`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`16`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` ff`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`a_s
    plt`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`plot`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`full_a`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` label`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string-interpolation"
          }}><span parentName="span" {...{
              "className": "token string"
            }}>{`f"p=`}</span><span parentName="span" {...{
              "className": "token interpolation"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span>{`p`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span></span><span parentName="span" {...{
              "className": "token string"
            }}>{`"`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre></div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "python"
    }}><pre parentName="div" {...{
        "className": "python"
      }}><code parentName="pre" {...{
          "className": "python"
        }}>{`xs `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` np`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`linspace`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0.5`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0.5`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`64`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`for`}</span>{` p `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`in`}</span>{` tqdm`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0.5`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1.5`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token builtin"
          }}>{`float`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'inf'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` desc`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"kernels"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
    ntk_kernel `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`for`}</span>{` i `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`in`}</span>{` trange`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`10`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` desc`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"averaging networks"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` leave`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token boolean"
          }}>{`False`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
        net `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` nn`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`Sequential`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`FF`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`16`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` p`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` MLP`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`32`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1024`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`4`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
        ntk_kernel `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`+=`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0.1`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`*`}</span>{` get_ntk`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`net`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` xs`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

    plt`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`figure`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'Full Spectrum'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
    fft `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` np`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`fft`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`fft`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`ntk_kernel`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`32`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
    plt`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`plot`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`np`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`fft`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`fftshift`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`fft`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`__abs__`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` label`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string-interpolation"
          }}><span parentName="span" {...{
              "className": "token string"
            }}>{`f'p=`}</span><span parentName="span" {...{
              "className": "token interpolation"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span>{`p`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span></span><span parentName="span" {...{
              "className": "token string"
            }}>{`'`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre></div>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "center"
          }}><strong parentName="th">{`Full A's`}</strong></th>
          <th parentName="tr" {...{
            "align": "center"
          }}><strong parentName="th">{`Spectrum`}</strong></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><img style={{
              "alignSelf": "center",
              "zoom": "18%"
            }} src="/ff3bd33cb35c020d0a34cd58cf942239/full_a_s.png" image="None" styles="{'margin': '0.5em'}" width="None" height="None" /></td>
          <td parentName="tr" {...{
            "align": "center"
          }}><img style={{
              "alignSelf": "center",
              "zoom": "18%"
            }} src="/5a226d8e83a4a19fedc313ee0b5479f5/fourier_features_ntk_full_spectrum.png" image="None" styles="{'margin': '0.5em'}" width="None" height="None" /></td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      